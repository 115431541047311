<template>
  <Alert :isActive="isError" v-if="isError">{{ errorMessage }}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
    <Navigation></Navigation>
    <div class="theme-container">
      <PageTitle
        :title="content['page-title']"
        :imgSrc="`${images['header.svg']}`"
      ></PageTitle>
      <p class="subtitle" v-html="content['page-description']" />
      <div class="section-container top">
        <Card
          :title="content['section-1-subsection-1-title']"
          :content="
            content['section-1-subsection-1-description']
          "
          :imgSrc="`${images['section-1-subsection-1-photo.png']}`"
          :linkList="[
            {
              link: content['section-1-subsection-1-link'],
            },
          ]"
        />
      </div>
      <div class="section-container">
        <Card
          :title="content['section-1-subsection-2-title']"
          :content="
            content['section-1-subsection-2-description'].split('\n')[0]
          "
          :optionalContent="[
            content['section-1-subsection-2-description'].split('\n')[2],
          ]"
          :imgSrc="`${images['section-1-subsection-2-photo.png']}`"
          :flipImage="true"
          :linkList="[
            {
              link: content['section-1-subsection-2-link'],
            },
          ]"
        />
      </div>
      <div class="section-container">
        <Card
          :title="content['section-1-subsection-3-title']"
          :content="
            content['section-1-subsection-3-description']
          "
          :optionalContent="[
            content['section-1-subsection-3-description2']
          ]"
          :imgSrc="`${images['section-1-subsection-3-photo.png']}`"
          :linkList="[
            {
              link: content['section-1-subsection-3-link'],
            },
          ]"
        />
      </div>
      <div class="section-container">
        <Card
          :title="content['section-1-subsection-4-title']"
          :content="
            content['section-1-subsection-4-description'].split('\n')[0]
          "
          :optionalContent="[
            content['section-1-subsection-4-description'].split('\n')[1],
          ]"
          :imgSrc="`${images['section-1-subsection-4-photo.png']}`"
          :flipImage="true"
          :linkList="[
            {
              link: content['section-1-subsection-4-link'],
            },
          ]"
        />
      </div>
      <div class="section-container">
        <Card
          :title="content['section-1-subsection-5-title']"
          :content="content['section-1-subsection-5-description']"
          :imgSrc="`${images['section-1-subsection-5-photo.png']}`"
          :linkList="[
            {
              link: content['section-1-subsection-5-link'],
            },
          ]"
        />
      </div>
      <ThankYouCard
        :cardNames="nameColumns"
      />

    
    </div>
      <BottomNavigator :order="5" />
  </Loading>
</template>

<script>
import BottomNavigator from "../components/BottomNavigator.vue";
import Card from "../components/Card.vue";
import Navigation from "../components/Navigation.vue";
import PageTitle from "../components/PageTitle.vue";
import ThankYouCard from "../components/ThankYouCard.vue";
import Loading from "@/components/Loading.vue";
import api from "@/services/api.js";
import Alert from "@/components/Alert.vue";


export default {
  data: () => ({
    content: {},
    images: {},
    thankYouCardNames: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
    nameColumns: [],
  }),
  methods: {
    async getData() {
      this.isError = false;
      this.isLoading = true;
      try {
        const { data } = await api.getContent("looking-ahead");
        console.log(data.content);
        this.content = data.content;
        this.images = data.images;
        let nameColumnOneRaw = data.content["section-2-name-column-1"];
        let nameColumnTwoRaw = data.content["section-2-name-column-2"];
        let nameColumnThreeRaw = data.content["section-2-name-column-3"];
        let nameColumnFourRaw = data.content["section-2-name-column-4"];
        let nameColumnOne = nameColumnOneRaw.split("\n");
        let nameColumnTwo = nameColumnTwoRaw.split("\n");
        let nameColumnThree = nameColumnThreeRaw.split("\n");
        let nameColumnFour = nameColumnFourRaw.split("\n");
        this.nameColumns = [
          nameColumnOne, nameColumnTwo, nameColumnThree, nameColumnFour
        ]
      } catch (err) {
        this.isError = true;
        this.errorMessage = err.message;
      } finally {
        this.isLoading = false;
      }
    },
  },

  async beforeRouteUpdate(to, from, next) {
    await this.getData();
    next();
  },

  async created() {
    await this.getData();
  },

  components: {
    PageTitle,
    Navigation,
    Card,
    ThankYouCard,
    BottomNavigator,
    Loading,
    Alert,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.theme-container {
  @include relative;
  :deep(.page-title) {
    .title-container {
      @include bg-color( #041B3F);
    }
  }
  .section-container {
    :deep(.rectangleCard-container)  {
      @include bg-color(takeda-white);
    }
    :deep(h1) {
      color: $font-color-1;
    }
  }
}

.subtitle {
  @include padding(5 10 5 10);
  @include responsive(phone) {
    @include padding(2 5 2 5);
  }
  @include responsive(tablet) {
    @include padding(4 5 4 5);
  }
}
.section-container {
  @include bg-color(takeda-light-grey);
  @include padding(10 10 18 10);
  z-index: 1;
  @include responsive(phone) {
    @include padding(1 1 1 0);
  }
  @include responsive(tablet) {
    @include padding(4 4 4 4);
  }
}

.top {
  padding-top: 150px;
  @include responsive(phone) {
    padding-top: 20px;
  }
}

.bottom {
  @include responsive(phone) {
    @include padding(1 1 1 1);
  }
}

.feedback-section {
  background-color: #f5f5f5;
  @include padding(3 17 3 17);
  @include margin(0 0 5 0);
  @include responsive(phone) {
    @include padding(2 2 2 2);
  }
}

.feedback-contact-text {
  @include color(takeda-sorairo-blue);
  font-weight: 600;
}
</style>
