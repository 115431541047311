<template>
  <div class="main-content-container">
    <h1>Thank you.</h1>
    <h3>
      From all of us at Slalom who had the opportunity to work with AbbVie in
      2021.
    </h3>
    <div class="flex-container columns">
      <div class="flex-3 column" v-for="column in cardNames">
        <div class="name-columns" v-for="name in column">
          {{name}}
        </div>
      </div>
    </div>
    <h3 class ="bottom-message">
      We cannot wait to see what the future holds and continue the journey together. #BetterTogether
    </h3>
  </div>
</template>

<script>

export default {
    props: [
    "cardNames"
  ],
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.main-content-container {
  @include bg-color(takeda-light-grey);
  @include padding(10);
  border-radius: 8px 8px 56px 8px;
  @include margin(4 4 15 4);
  @include responsive(phone) {
    @include padding(3 3 3 3);
    @include margin(2 2 4 2);
  }
  @include responsive(tablet) {
  @include padding(4 3 4 3);
    @include margin(4 4 4 4);
  }
}

h1 {
  font-size: 48px;
}

.bottom-message {
  @include color(takeda-sorairo-blue);
  font-size: 24px;
}

.column {
  font-weight: 300;
  @include responsive(phone) {
    font-size: 12px;
  }
  @include responsive(tablet) {
    font-size: 12px;
  }
}

.columns {
  @include margin(4 0 4 0);
 
}

// .name-columns {
//   width: 50%;
// }

</style>
